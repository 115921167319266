import prodConfig from './prod.json';
import qaConfig from './qa.json';
import stageConfig from './stage.json';
import demoConfig from './demo.json';

interface Config {
  stripePublicKey: string;
  apiKey: string;
  apiBaseUrl: string;
  environment: string;
  siteKey: string;
  measurementId: string;
  hotjarSiteID: string;
}

let config: Config;

switch (process.env.REACT_APP_ENV) {
  case 'production':
    config = prodConfig;
    break;
  case 'qa':
    config = qaConfig;
    break;
  case 'stage':
    config = stageConfig;
    break;
  case 'demo':
    config = demoConfig;
    break;
  default:
    config = qaConfig;
    break;
}

export default config;
