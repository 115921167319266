import React, { FC } from 'react';

import { ACModal } from 'shared/components';

interface NotificationModalProps {
  open: boolean;
  onClose: () => void;
}

export const NotificationModal: FC<NotificationModalProps> = ({ open, onClose }) => {
  return (
    <ACModal
      title="Your quote has expired"
      content={
        <div className="ta-tooltip-container">
          <p> In order to generate your quote correctly, please click on “requote”.</p>
        </div>
      }
      showCloseButton={false}
      showDivider={true}
      open={open}
      onClose={onClose}
      buttonText="Requote"
    />
  );
};
