import React, { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACFooter,
  ACHeader,
  ACIcon,
  ACProgress,
  ACQuoteMobile,
  ACErrorBoundary,
  ACChatbot,
} from 'shared/components';
import logoIcon from 'shared/assets/icon/logo.svg';
import {
  useGetBranding,
  useModal,
  useReadLocalStorage,
  useUpdateTaxAuditApp,
  useWindowSize,
} from 'shared/hooks';
import { useGetTaxAuditApp } from 'shared/hooks/api/useGetTaxAuditApp';
import { RootState } from 'shared/store/store';
import { updateQuote } from 'shared/store/slices/taa.slice';
import { Quote, TaaResponseType } from 'shared/models';
import { NotificationModal } from 'shared/sections/modal/notificationModal';
import { useRestoreQuote } from 'shared/hooks/api/useRestoreQuote';

const isEntityTypeMissing = (TaaData?: TaaResponseType) => {
  if (!TaaData) return false;

  const hasPrimaryEntityType =
    TaaData.policyData?.primaryEntity?.type !== undefined &&
    TaaData.policyData?.primaryEntity?.type !== null;

  const hasAdditionalEntityWithoutType = TaaData.policyData?.additionalEntities?.some(
    entity => entity?.type !== undefined && entity?.type === null
  );

  return !hasPrimaryEntityType || hasAdditionalEntityWithoutType;
};

const excludedPathList = [
  'summary',
  'review',
  'pay',
  'complete',
  'entity-selection',
  'business-entity',
];

interface ACLayoutProps {
  children?: React.ReactNode;
  isDisplayProgressBar?: boolean;
  isLogoClickable?: boolean;
}

export const ACLayout: FC<ACLayoutProps> = ({
  children,
  isDisplayProgressBar = true,
  isLogoClickable = true,
}) => {
  const [partnerId, setPartnerId] = useState<string | null>('');
  const windowsSize = useWindowSize();
  const { id } = useParams();
  const { data: TaaData } = useGetTaxAuditApp(id);
  const updateTAAService = useUpdateTaxAuditApp(id);
  const { quote, apiError, isExpired } = useSelector((state: RootState) => state.TaaApplication);
  const storedQuote = useReadLocalStorage<Quote>('quote');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { data: brandingData } = useGetBranding(partnerId);
  const { mutateAsync: restoreQuote } = useRestoreQuote(id ?? '');

  const modal = useModal();

  const isEnableMobileQuote = excludedPathList.some(keyword => location.pathname.includes(keyword));
  const groupData = partnerId ? brandingData?.branding : TaaData?.branding;

  const handleGoBackHome = async () => {
    if (isLogoClickable) {
      await updateTAAService.mutateAsync(
        {
          stage: 'EntityType_Stage',
        },
        {
          onSuccess: () => {
            navigate(`/entity-selection/${id}`);
          },
        }
      );
    }
  };

  const handleRestoreQuote = async () => {
    await restoreQuote();

    // Navigate to summary page
    await updateTAAService.mutateAsync(
      {
        stage: 'Summary_Stage',
      },
      {
        onSuccess: () => {
          navigate(`/summary/${id}`);
          navigate(0);
        },
      }
    );
  };

  useEffect(() => {
    // Check if the entity type is missing
    if (isEntityTypeMissing(TaaData) && TaaData?.stage === 'Summary_Stage') {
      navigate(`/missing-entity-type/${id}`);
    }

    // if (isEntityTypeMissing(TaaData)) {
    //   navigate(`/missing-entity-types/${id}`);
    // }

    // Check the payment status
    if (
      TaaData?.status === 'Issued' ||
      (!location.pathname.includes('pay') && TaaData?.status === 'PaymentProcessing') ||
      (TaaData?.stage === 'Complete_Stage' && TaaData?.status === 'PaymentProcessing')
    ) {
      navigate(`/complete/${id}`);
    }

    if (TaaData?.entityGroup === 'IndividualGroup' && !quote) {
      dispatch(updateQuote(storedQuote));
    }

    // Store the partnerId to the state
    // in case of it request the branding data again moving to next screen (fix the transition issue)
    if (queryParams.get('partner')) {
      setPartnerId(queryParams.get('partner'));
    }
  }, [TaaData]);

  // Show expired modal
  useEffect(() => {
    if (isExpired) {
      modal.handleOpen();
    }
  }, [isExpired]);

  return (
    <div className="flex flex-col justify-between bg-[#efeff5] text-[.9375rem] font-normal leading-[1.25rem] min-h-screen min-w-full">
      <ACHeader>
        <ACIcon
          imgSrc={groupData?.isGroup ? `${groupData?.logoPath}?w=400` : logoIcon}
          className={`h-10 ${isLogoClickable && 'hover:cursor-pointer'}`}
          onClick={handleGoBackHome}
        />
      </ACHeader>

      {isDisplayProgressBar && (
        <ACProgress value={TaaData?.progress || 18} bgColor={groupData?.colour} />
      )}

      {windowsSize.width < 1280 && !isEnableMobileQuote && (quote || TaaData?.quote) && (
        <ACQuoteMobile quote={quote || TaaData!.quote} />
      )}

      <div className="mb-auto px-3 xl:px-0 w-full xl:max-w-[1296px] mx-auto">
        {apiError !== null ? (
          <ACErrorBoundary error={apiError} isApiError={true} isExpired={isExpired} />
        ) : (
          <ErrorBoundary FallbackComponent={ACErrorBoundary}>
            {children || <Outlet />}
          </ErrorBoundary>
        )}
      </div>

      <ACFooter
        legals={groupData?.legals}
        privacyUrl={groupData?.privacyUrl}
        contactLink={groupData?.contactUsUrl}
        isGroup={groupData?.isGroup}
      />

      <ACChatbot taaData={TaaData} />

      <NotificationModal open={modal.open} onClose={handleRestoreQuote} />
    </div>
  );
};
