export const turnoverBands = [
  { label: 'Under $500K', value: 500000 },
  { label: '$500K - $1M', value: 1000000 },
  { label: '$1M - $3M', value: 3000000 },
  { label: '$3M - $6M', value: 6000000 },
  { label: '$6M - $10M', value: 10000000 },
  { label: '$10M - $25M', value: 25000000 },
  { label: '$25M - $50M', value: 50000000 },
];

export const customizedQuoteTurnoverBands = [
  { label: 'Under $500K', value: 500000 },
  { label: '$500K - $1M', value: 1000000 },
  { label: '$1M - $3M', value: 3000000 },
  { label: '$3M - $6M', value: 6000000 },
  { label: '$6M - $10M', value: 10000000 },
  { label: '$10M - $25M', value: 25000000 },
  { label: '$25M - $50M', value: 50000000 },
  { label: '$50M - $75M', value: 75000000 },
  { label: '$75M - $100M', value: 100000000 },
];

export const AUStates = ['NSW', 'VIC', 'QLD', 'WA', 'ACT', 'SA', 'TAS', 'NT'];

export const AUStateOptions = AUStates.map(state => ({ value: state, label: state }));

export const entityTypes = [
  {
    label: 'Partnership',
    value: 'Partnership',
  },
  {
    label: 'Private Company',
    value: 'PrivateCompany',
  },
  {
    label: 'Sole Trader',
    value: 'SoleTrader',
  },
  {
    label: 'Trust',
    value: 'Trust',
  },
];

export const entityTypesWithoutIndividual = [
  {
    label: 'Partnership',
    value: 'Partnership',
  },
  {
    label: 'Private Company',
    value: 'PrivateCompany',
  },
  {
    label: 'Sole Trader',
    value: 'SoleTrader',
  },
  {
    label: 'Trust',
    value: 'Trust',
  },
  {
    label: 'Individual',
    value: 'Individual',
  },
];

export const entityTypeMapping = {
  Individual: 'Individual',
  Partnership: 'Partnership',
  PrivateCompany: 'Private Company',
  SoleTrader: 'Sole Trader',
  Trust: 'Trust',
};

export const stageToPathMapping = {
  Primary_Stage: '/business-entity/:id',
  Contact_Stage: '/contact/:id',
  LimitOfCover_Stage: '/limitofcover/:id',
  BusinessGroupTurnover_Stage: '/business-group-turnover/:id',
  AdditionalIndividuals_Stage: '/additional-individuals-selection/:id',
  AdditionalTrusts_Stage: '/additional-trusts-selection/:id',
  AdditionalBusinesses_Stage: '/additional-businesses-selection/:id',
  AdditionalTrustee_Stage: '/additional-trustee-selection/:id',
  Business_Stage: '/business-entity/:id',
  Summary_Stage: '/summary/:id',
  Pay_Stage: '/pay/:id',
  PaymentRetry_Stage: '/paymentretry/:id',
  Complete_Stage: '/complete/:id',
  MissingEntityType_Stage: '/missing-entity-type/:id',
};

export type StageToPathMapping = {
  [key in keyof typeof stageToPathMapping]: string;
};
