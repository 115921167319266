import { useMutation } from '@tanstack/react-query';
import { useAxios } from 'shared/hooks';

import { TaaResponseType } from 'shared/models';

export const useRestoreQuote = (policyId: string) => {
  const { put } = useAxios();

  return useMutation<TaaResponseType, Error>(
    async () => await put<null, TaaResponseType>(`/api/v2/policyapplications/${policyId}/restore`)
  );
};
